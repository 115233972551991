<template>
    <div>
        <SearchForm @onSearch="filterSearch" />
        <div
            v-loading="loading"
            class="shadow-lg rounded-lg pt-3 pb-5 bg-white my-10 max-h-[600px] overflow-y-auto"
            element-loading-text="載入中"
            element-loading-spinner="el-icon-loading text-red-600"
            element-loading-customClass="flex justify-center items-center">
            <h1 v-if="users.length > 0" class="text-center font-bold">總筆數：{{ users.length }}</h1>
            <table class="myTable">
                <thead>
                    <tr>
                        <th>暱稱</th>
                        <th>Email</th>
                        <th>生日</th>
                        <th>身份</th>
                        <th>帳號狀態</th>
                        <th>Email驗證狀態</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in users" :key="index" class="text-center duration-200 transition">
                        <td>{{ $subString(item.name, 10) }}</td>
                        <td>{{ item.email }}</td>
                        <td>{{ item.birthday | formatDate }}</td>
                        <td>{{ item.role > 0 ? "服務商" : "會員" }}</td>
                        <td :class="item.status === 0 ? 'text-black' : 'text-orange-600'">
                            {{ item.status | showStatusText }}
                        </td>
                        <td>
                            {{ item.email_verified_at === null ? "未驗證" : "已驗證" }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <VueJsonToCsv
            v-if="users.length > 0"
            :json-data="users"
            :showLabels="false"
            :labels="labels"
            :csv-title="`email-${$moment().format('YYYYMMDDHHmm')}`">
            <div class="flex justify-center">
                <btn size="md:text-sm text-xs px-4 py-2 rounded min-w-[70px] max-w-[200px]" :disabled="loading" customClass="flex-shrink mr-2">
                    下載Email資料
                </btn>
            </div>
        </VueJsonToCsv>
    </div>
</template>

<script>
import SearchForm from "./SearchForm.vue";
import VueJsonToCsv from "vue-json-to-csv";
import Btn from "@/components/Button.vue";
export default {
    components: {
        SearchForm,
        VueJsonToCsv,
        Btn,
    },
    filters: {
        showStatusText(val) {
            switch (val) {
                case 0:
                    return "使用中";
                case 1:
                    return "使用中";
                case 2:
                    return "使用中";
                case -1:
                    return "停權";
                case -2:
                    return "永久停權";
                default:
                    return val;
            }
        },
    },
    data() {
        return {
            users: [],
            loading: false,
            testData: [
                { name: "測試名稱", email: "test@gmail.com", status: 0, birthday: new Date(), email_verified_at: new Date() },
                { name: "測試名稱2", email: "test2@gmail.com", status: -1, birthday: new Date(), email_verified_at: null },
            ],
            labels: {
                name: { title: "別名" },
                email: { title: "EMAIL" },
            },
        };
    },
    methods: {
        /**
         * @param { tpye Object(物件) } filterData 搜尋過濾資料
         */
        filterSearch(filterData) {
            this.filterData = {};
            if (filterData.emailVerified === 0) {
                delete filterData.emailVerified;
            }
            this.filterData = { ...filterData };
            console.log(this.filterData);
            this.getLists({
                ...this.filterData,
            });
        },
        async getLists(filterData) {
            this.loading = true;
            try {
                const { data } = await this.$api.GetMarketingNotificationUserEmailApi(filterData);
                const users = data.map((item) => ({
                    name: item.name,
                    email: item.email,
                    birthday: item.birthday,
                    email_verified_at: item.email_verified_at,
                    status: item.status,
                    role: item.role,
                }));
                this.users = users;
                this.loading = false;
            } catch (err) {
                this.loading = false;
                console.log(err);
                this.$message({
                    type: "error",
                    message: "取得Email失敗",
                });
            }
        },
    },
};
</script>
