<template>
    <div class="shadow-lg rounded-lg bg-white px-5 pt-3 pb-5">
        <el-form ref="form" :model="form">
            <div class="flex items-end">
                <div class="flex-1">
                    <div class="grid grid-cols-12 gap-4 w-full">
                        <div class="col-span-3">
                            <el-form-item class="mb-0" prop="birthday">
                                <label class="text-gray-400"> 生日 </label>
                                <div class="flex">
                                    <el-select v-model="form.birthday_year" class="flex-1" clearable placeholder="年份">
                                        <el-option v-for="item in years" :key="item" :label="item" :value="item"></el-option>
                                    </el-select>
                                    <el-select v-model="form.birthday_month" class="flex-1" clearable placeholder="月份">
                                        <el-option v-for="item in 12" :key="item" :label="item" :value="item"></el-option>
                                    </el-select>
                                    <el-select v-model="form.birthday_day" class="flex-1" clearable placeholder="日期">
                                        <el-option v-for="item in 31" :key="item" :label="item" :value="item"></el-option>
                                    </el-select>
                                </div>
                            </el-form-item>
                        </div>
                        <div class="col-span-3">
                            <el-form-item class="mb-0" prop="status">
                                <label class="text-gray-400">
                                    狀態
                                    <el-select v-model="form.status" class="w-full" clearable>
                                        <el-option v-for="(status, index) in statusOptions" :key="index" :label="status.label" :value="status.value">
                                            {{ status.label }}
                                        </el-option>
                                    </el-select>
                                </label>
                            </el-form-item>
                        </div>
                        <div class="col-span-3">
                            <el-form-item class="mb-0" prop="role">
                                <label class="text-gray-400">
                                    身份
                                    <el-select v-model="form.role" class="w-full" clearable>
                                        <el-option v-for="(role, index) in roleOptions" :key="index" :label="role.label" :value="role.value">
                                            {{ role.label }}
                                        </el-option>
                                    </el-select>
                                </label>
                            </el-form-item>
                        </div>
                        <div class="col-span-3">
                            <el-form-item class="mb-0" prop="emailVerified">
                                <label class="text-gray-400">
                                    Email驗證
                                    <el-select v-model="form.emailVerified" class="w-full" clearable>
                                        <el-option
                                            v-for="(emailVerified, index) in emailVerifiedOptions"
                                            :key="index"
                                            :label="emailVerified.label"
                                            :value="emailVerified.value">
                                            {{ emailVerified.label }}
                                        </el-option>
                                    </el-select>
                                </label>
                            </el-form-item>
                        </div>
                    </div>
                </div>
                <div class="flex-grow-0 ml-4 min-w-[150px]">
                    <btn
                        size="md:text-sm text-xs px-4 py-2 rounded min-w-[70px] max-w-[200px]"
                        customClass="flex-shrink mr-1"
                        @onClick="onSubmit('form')">
                        搜尋
                    </btn>
                    <btn
                        color="border border-black"
                        size="md:text-sm text-xs px-4 py-2 rounded min-w-[70px] max-w-[200px]"
                        customClass="flex-shrink"
                        @onClick="resetData">
                        重置
                    </btn>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
// 判斷是否有選擇值
import { checkHaveSelectData } from "@/service/anyService";
// 導入自定義 按鈕
import Btn from "@/components/Button.vue";

export default {
    components: {
        Btn,
    },
    data() {
        return {
            form: {},

            // 帳號狀態
            statusOptions: [
                { label: "使用中", value: [0, 1, 2] },
                { label: "停權", value: [-1] },
                { label: "永久停權", value: [-2] },
            ],
            // 身份選擇
            roleOptions: [
                { label: "服務商", value: [1, 2] },
                { label: "會員", value: [0] },
            ],
            // Email 驗證狀態選擇
            emailVerifiedOptions: [
                { label: "有驗證", value: 1 },
                { label: "包含未驗證", value: 0 },
            ],
            // 搜尋條件
            filterData: {},
            // 搜尋年份
            years: [],
        };
    },
    methods: {
        /**
         * 表單發送
         * @param { type String(字串) } formName 傳送表單名稱
         * @example ref="form"
         */
        async onSubmit(formName) {
            try {
                // 等待表單驗證是否成功 try catch 會自動判斷是 true 或 false 因次不用寫 if 判斷
                await this.$refs[formName].validate();
                this.filterSearch();
            } catch (err) {
                console.log(err);
                this.$message({
                    type: "error",
                    message: "表單驗證失敗",
                });
            }
        },
        // 搜尋條件
        async filterSearch() {
            // 先清空生日值
            this.form.birthday = null;
            // 判斷是否有輸入月份 跟 日期
            if (!this.$isEmpty(this.form.month) && !this.$isEmpty(this.form.day)) {
                // 組合生日
                this.form.birthday = this.$moment(this.form.month + "-" + this.form.day).format("MM-DD");
            }
            // 判斷是否有輸入手機號碼
            if (!this.$isEmpty(this.form.mobile)) {
                // 組合手機號碼
                this.form.phone = this.form.countryCode + this.form.mobile;
            } else {
                // 須清空組合後得手機號碼 否則還是一樣會列入搜尋條件
                this.form.phone = null;
            }
            // 判斷是否有值 有值就帶入搜尋條件
            checkHaveSelectData(
                [
                    this.form.birthday_year,
                    this.form.birthday_month,
                    this.form.birthday_day,
                    this.form.status,
                    this.form.role,
                    this.form.emailVerified,
                ],
                ["birthday_year", "birthday_month", "birthday_day", "status", "role", "emailVerified"],
                this.filterData
            );
            this.$emit("onSearch", this.filterData);
        },
        // 清空表單資料
        resetData() {
            this.form = {};
            this.$refs.form.resetFields();
            this.$emit("onReset", {});
        },
        // 取得1911 至
        getYears() {
            const year = Number(this.$moment().format("YYYY"));
            for (let i = 1911; i <= year - 18; i++) {
                this.years.unshift(i);
            }
        },
    },
    created() {
        this.getYears();
    },
};
</script>
